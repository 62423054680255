<template>
  <div class="row m-0 fixed">
    <div class="col-10 col-sm-6 col-md-5 col-lg-5 col-xl-4 col-xxl-3 p-4 card_item position-relative" :class="[cols]">
      <ios-close-icon class="inline-icon position-absolute position_absolute cursor" @click="$emit('close')" v-if="!hideClose" />
      <div class="row mx-0">
        <div class="col-12 p-0">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  props: ['cols', 'hideClose'],
  name: 'Popup'
}
</script>

<style scoped>
.fixed {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  justify-content: center;
  align-items: center;
}
.position_absolute {
  top: 0.5rem;
  right: 0.5rem;
}
.card_item {
  background-color: #fff;
  border-radius: 13px;
}
</style>
